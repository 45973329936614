import { themes } from "../react-components/styles/theme";
import { waitForDOMContentLoaded } from "./async-utils";
import configs from "./configs";

// Node these should be synchronized with the top of shared.scss
const DEFAULT_ACTION_COLOR = "#FF3464";
const DEFAULT_ACTION_COLOR_LIGHT = "#FF74A4";

const DEFAULT_COLORS = {
  "action-color": DEFAULT_ACTION_COLOR,
  "action-label-color": DEFAULT_ACTION_COLOR,
  "action-color-disabled": DEFAULT_ACTION_COLOR_LIGHT,
  "action-color-highlight": DEFAULT_ACTION_COLOR_LIGHT,
  "action-text-color": "#FFFFFF",
  "action-subtitle-color": "#F0F0F0",
  "notice-background-color": "#2F80ED",
  "notice-text-color": "#FFFFFF",
  "favorited-color": "#FFC000",
  "nametag-color": "#000000",
  "nametag-volume-color": "#7ED320",
  "nametag-text-color": "#FFFFFF",
  "nametag-border-color": "#7ED320",
  "nametag-border-color-raised-hand": "#FFCD74"
};

function getThemeColor(name) {
  const themeId = window.APP?.store?.state?.preferences?.theme;

  const theme =
    (themeId && configs.APP_CONFIG?.theme?.themes?.find(theme => theme.id === themeId)) ||
    configs.APP_CONFIG?.theme?.themes?.find(theme => theme.default === true);
  if (theme?.variables?.[name]) {
    return theme.variables[name];
  }

  return DEFAULT_COLORS[name];
}

function activateTheme() {
  // CustomEvent Polyfill
  if (typeof window.CustomEvent !== "function") {
    const CustomEventPolyfill = function (event, params) {
      params = params || { bubbles: false, cancelable: false, detail: null };
      const evt = document.createEvent("Event");
      evt.initEvent(event, params.bubbles, params.cancelable);
      evt.detail = params.detail; // Add `detail` property manually
      return evt;
    };
    CustomEventPolyfill.prototype = Event.prototype;
    window.CustomEvent = CustomEventPolyfill;
  }

  const actionColor = getThemeColor("action-color");
  const actionHoverColor = getThemeColor("action-color-highlight");

  if (document.querySelector("#rounded-text-button")) {
    document
      .querySelector("#rounded-text-button")
      .setAttribute(
        "text-button",
        `textHoverColor: ${actionHoverColor}; textColor: ${actionColor}; backgroundColor: #fff; backgroundHoverColor: #aaa;`
      );

    document
      .querySelector("#rounded-button")
      .setAttribute(
        "text-button",
        `textHoverColor: ${actionHoverColor}; textColor: ${actionColor}; backgroundColor: #fff; backgroundHoverColor: #aaa;`
      );

    document
      .querySelector("#rounded-text-action-button")
      .setAttribute(
        "text-button",
        `textHoverColor: #fff; textColor: #fff; backgroundColor: ${actionColor}; backgroundHoverColor: ${actionHoverColor}`
      );

    document
      .querySelector("#rounded-action-button")
      .setAttribute(
        "text-button",
        `textHoverColor: #fff; textColor: #fff; backgroundColor: ${actionColor}; backgroundHoverColor: ${actionHoverColor}`
      );
  }

  // Create a CustomEvent
  const updateThemeEvent = new CustomEvent("update_theme", {
    bubbles: true,
    cancelable: true
  });

  // Validate that the event is an instance of Event
  if (!(updateThemeEvent instanceof Event)) {
    console.error("updateThemeEvent is not a valid Event instance.");
    throw new TypeError("updateThemeEvent is not of type 'Event'.");
  }

  console.log("Dispatching event:", updateThemeEvent);

  // Dispatch the event
  try {
    window.dispatchEvent(updateThemeEvent);
  } catch (error) {
    console.error("Failed to dispatch event. Error:", error);
  }
}

const onStoreChanged = (function () {
  let themeId;
  return function onStoreChanged() {
    const newThemeId = window.APP?.store?.state?.preferences?.theme;
    if (themeId !== newThemeId) {
      themeId = newThemeId;
      activateTheme();
    }
  };
})();

waitForDOMContentLoaded().then(() => {
  if (configs.APP_CONFIG && configs.APP_CONFIG.theme && configs.APP_CONFIG.theme["dark-theme"]) {
    document.body.classList.add("dark-theme");
  } else {
    document.body.classList.add("light-theme");
  }
  // activateTheme();
  window.APP?.store?.addEventListener("statechanged", onStoreChanged);
});

function applyThemeToTextButton(el, highlighted) {
  el.setAttribute(
    "text-button",
    "backgroundColor",
    highlighted ? getThemeColor("action-color-highlight") : getThemeColor("action-color")
  );
  el.setAttribute(
    "text-button",
    "backgroundHoverColor",
    highlighted ? "#aaa" : getThemeColor("action-color-highlight")
  );
}

function getDefaultTheme() {
  return themes.find(t => t.default) || themes[0];
}

export { applyThemeToTextButton, getThemeColor, getDefaultTheme };
